<h5 class="bsDesc"
  [ngClass]="isContentToggled?'overflow-auto mb-2':''"
>
  {{ content }}
</h5>

<div *ngIf="isContentToggled" class="demo_overlay"></div>

<a *ngIf="showToggleBtn"
  class="more_btn"
  (click)="toggleContent()"
  (keydown)="toggleContent()"
>
  View {{isContentToggled ? 'Less':'More'}}
</a>

