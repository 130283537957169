import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from '../store/common.selectors';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { loginUserData } from '../login/store/login.reducers';
import { SsoUserData } from '../appdata/auth.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard {
  constructor(
    private readonly store: Store,
    private router: Router,
    private shareservice: SharedService
  ) {}
  loginUserData: SsoUserData;
  private common: any = {
    standalon: {
      customer: [
        'analytics',
        'dashboard',
        'reports',
        'my-profile',
        'business-settings',
        'support',
      ],
      cc: ['support'],
    },
    sso: {
      customer: [
        'analytics',
        'dashboard',
        'reports',
        'business-settings',
        'support',
      ],
      sa: ['analytics', 'dashboard', 'reports', 'business-settings'],
      cc: ['support'],
    },
  };

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (environment.sso) {
      return this.store.select(selectSsoProfilesDtlsData).pipe(
        map((res) => {
          if (res.loggedInUserDetails) {
            this.loginUserData = res.loggedInUserDetails;
            const permissions = res.loggedInUserDetails.rbac.rbac_policies;
            if (!permissions) {
              //return false;
              // Redirect to login or another route
              return this.router.parseUrl('/page-not-found');
            }
            return this.checkCompRouteAccess(permissions, state.url) ||
              this.checkDashboardRouteAccess(permissions, state.url) ||
              this.checkControlCenterRouteAccess(permissions, state.url) ||
              this.checkChildRoutePermissions(permissions, state.url) ||
              this.commondRouteAccess(state.url, 'sso')
              ? true
              : this.router.parseUrl('/page-not-found');
          }
        })
      );
    } else {
      return this.store.select(selectProfilesDtlsData).pipe(
        map((res) => {
          const permissions = this.getPermissions(res);
          if (!permissions) {
            // Handle case where permissions are not available
            return this.router.parseUrl('/page-not-found');
          }
          return this.checkCompRouteAccess(permissions, state.url) ||
            this.checkDashboardRouteAccess(permissions, state.url) ||
            this.checkControlCenterRouteAccess(permissions, state.url) ||
            this.checkChildRoutePermissions(permissions, state.url) ||
            this.commondRouteAccess(state.url, 'standalon')
            ? true
            : this.router.parseUrl('/page-not-found');
        })
      );
    }
  }

  private getPermissions(data: any): any {
    if (
      !data ||
      !data.loggedInUserDetails ||
      !data.loggedInUserDetails.profile ||
      !data.loggedInUserDetails.profile.acl.rbac
    ) {
      return null;
    }
    const accountType = data.loggedInUserDetails.account.account_type;
    return data.loggedInUserDetails.profile.acl.rbac_list.find(
      (t: any) => t.account_type === accountType
    ).rbac_policies;
  }

  private commondRouteAccess(url: string, type: string): boolean {
    // Extract path segments from the URL
    const pathSegments = url.split('/').filter((segment) => segment);

    // Check if the URL matches any category and type in the common object
    return ['cc', 'customer', 'sa'].some(
      (category) =>
        pathSegments.includes(category) &&
        this.matchesCommon(pathSegments, category, type)
    );
  }

  private matchesCommon(
    segments: string[],
    category: string,
    type: string
  ): boolean {
    // Check if any segment in the URL matches the list of the specified category and type
    return segments.some((segment) =>
      this.common[type]?.[category]?.includes(segment)
    );
  }

  private checkDashboardRouteAccess(permissions: any, url: string) {
    if (url.includes('cc') && url.includes('dashboard')) {
      return permissions.control_centre_dashboard_analytics;
    }
    if (
      url.includes(this.shareservice.getRoutingUrl()) &&
      url.includes('dashboard')
    ) {
      return true;
    }
  }

  private checkCompRouteAccess(permissions: any, url: string): boolean {
    const routePermissions = this.getRoutePermissions(permissions, url);
    return routePermissions
      ? routePermissions.full_control || routePermissions.can_view
      : false;
  }

  private checkControlCenterRouteAccess(
    permissions: any,
    url: string
  ): boolean {
    const routePermissions = this.getControlCenterRoutePermissions(
      permissions,
      url
    );
    return routePermissions
      ? routePermissions.full_control || routePermissions.can_view
      : false;
  }

  private getRoutePermissions(permissions: any, url: string): any {
    if (url.includes(this.shareservice.getRoutingUrl())) {
      if (url.includes('campaign-manager')) {
        return permissions.campagin_management;
      } else if (url.includes('templates')) {
        return permissions.template_management;
      } else if (url.includes('consent-management')) {
        return permissions.consent_management;
      } else if (url.includes('media')) {
        return permissions.drive_management;
      } else if (url.includes('my-profile')) {
        return permissions.profile_management;
      }
    }
    return null;
  }

  private getControlCenterRoutePermissions(permissions: any, url: string): any {
    if (
      url.includes('cc') &&
      url.includes('business-accounts') &&
      this.loginUserData?.account?.account_type?.toUpperCase() !== 'ROOT'
    ) {
      return permissions.waba_business_account_management;
    }
    if (
      url.includes('cc') &&
      url.includes('service-accounts') &&
      this.loginUserData?.account?.account_type?.toUpperCase() === 'ROOT'
    ) {
      return permissions.waba_business_account_management;
    }
    if (url.includes('cc') && url.includes('view-business-account')) {
      return permissions.waba_business_account_management;
    } else if (url.includes('cc') && url.includes('profiles')) {
      return permissions.profile_management;
    } else if (url.includes('cc') && url.includes('roles')) {
      return permissions.acl_management;
    }
    return null;
  }

  private checkChildRoutePermissions(permissions: any, url: string): any {
    if (url.includes('campaign-manager') && url.includes('create')) {
      return permissions.campagin_management.can_create;
    } else if (url.includes('templates') && url.includes('create')) {
      return permissions.template_management.can_create;
    } else if (url.includes('campaign-manager') && url.includes('edit')) {
      return permissions.campagin_management.can_update;
    } else if (url.includes('templates') && url.includes('edit')) {
      return permissions.template_management.can_update;
    } else if (url.includes('campaign-manager') && url.includes('view')) {
      return permissions.campagin_management.can_view;
    } else if (url.includes('customer') && url.includes('analytics')) {
      return permissions.settings.full_control || permissions.settings.can_view;
    } else if (url.includes('customer') && url.includes('support')) {
      return permissions._support;
    }
    return null;
  }
}
