import { Action, createReducer, on } from '@ngrx/store';
import * as dashboardactions from './dashboard.actions';

export interface State {
  dashboarddata: any;
  selectedAccountDetails: any;
  error: any;
}

export const initialState: State = {
  dashboarddata: null,
  selectedAccountDetails: null,
  error: null,
};

const dashboardDataReducer = createReducer(
  initialState,
  on(
    dashboardactions.getChannelCredentialsSuccess,
    (state, { data }): State => ({
      ...state,
      dashboarddata: data,
      selectedAccountDetails: null,
      error: null,
    })
  ),
  on(
    dashboardactions.getChannelCredentialsFailure,
    (state, { error }): State => ({
      ...state,
      dashboarddata: null,
      selectedAccountDetails: null,
      error: error,
    })
  ),
  on(
    dashboardactions.getServiceAccountsSuccess,
    (state, { data }): State => ({
      ...state,
      dashboarddata: data,
      selectedAccountDetails: null,
      error: null,
    })
  ),
  on(
    dashboardactions.getServiceAccountsFailure,
    (state, { error }): State => ({
      ...state,
      dashboarddata: null,
      selectedAccountDetails: null,
      error: error,
    })
  ),
  on(
    dashboardactions.updateChannelCredentialsSuccess,
    (state, { data }): State => ({
      ...state,
      dashboarddata: data,
      selectedAccountDetails: null,
      error: null,
    })
  ),
  on(
    dashboardactions.updateChannelCredentialsFailure,
    (state, { error }): State => ({
      ...state,
      selectedAccountDetails: null,
      dashboarddata: null,
      error: error,
    })
  ),
  on(
    dashboardactions.resetChannelCredentials,
    (state): State => ({
      ...state,
      dashboarddata: null,
      error: null,
    })
  ),
  on(
    dashboardactions.chooseBusinesAccount,
    dashboardactions.chooseServiceAccount,
    (state, { data }): State => ({
      ...state,
      dashboarddata: null,
      selectedAccountDetails: data,
      error: null,
    })
  )
);

export function reducer(state: State | undefined, action: Action): any {
  return dashboardDataReducer(state, action);
}

export const getDashboardData = (state: State) => {
  return {
    dashboarddata: state.dashboarddata,
    selectedAccountDetails: state.selectedAccountDetails,
    error: state.error,
  };
};
