import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { Datum } from 'src/app/appdata/channeldata.model';
import * as dashboardactions from 'src/app/customer/dashboard/store/dashboard.actions';
import { environment } from 'src/environments/environment';
import { AuthUserData, SsoUserData } from '../appdata/auth.model';
import { WhitelabelingData } from '../appdata/initialwlb.data';
import {
  notificationMessages,
  NotificationMsg,
} from '../appdata/notifications.data';
import { Channels, channels } from '../appdata/shared.data';
import * as authactions from '../login/store/login.actions';
import { SharedService } from '../services/shared.service';
import { WhiteLabelService } from '../services/whitelabel.service';
import * as commonactions from '../store/common.actions';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from '../store/common.selectors';
import { selectChannelCredentials } from '../customer/dashboard/store/dashboard.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  noOfDays: any;
  userDetails: AuthUserData | SsoUserData;
  permissionDetails: any;
  selectedChannel: any;
  whiteLabeledData: any = WhitelabelingData;
  selectedBA: any;
  selectedBsp: any;
  channelsList: Channels[] = channels;
  channelSub: Subscription;
  @Input() ForModule: string;
  showLicenseAlert: boolean = true;
  activeaccounts: Array<any> = [];
  channel_credentails: Datum[];
  notificationsMsgs: NotificationMsg[];
  focusSub: Subscription;
  bsp: Array<any> = [];
  active = 1;
  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
  };
  permissions: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  sso = environment.sso;
  clientId: string;
  constructor(
    private readonly store: Store,
    private modalService: NgbModal,
    private alertMsg: AlertService,
    private whtlbservice: WhiteLabelService,
    private shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.wlbres) {
          this.whiteLabeledData = res.wlbres.wlb_settings
            ? res.wlbres.wlb_settings
            : res.wlbres;
          if (typeof this.whiteLabeledData === 'string') {
            this.whiteLabeledData = JSON.parse(this.whiteLabeledData);
          }
          // localStorage.setItem(
          //   'waba_wbl',
          //   JSON.stringify(this.whiteLabeledData)
          // );
          this.whtlbservice.applyWhiteLabeling(this.whiteLabeledData);
        } else if (res.error) {
          if (res.error.message && res.error.message === 'No data found') {
            return;
          }
        }
      });

    this.notificationsMsgs = notificationMessages;
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res) {
            if (res.loggedInUserDetails) {
              this.userDetails = res.loggedInUserDetails;
              this.permissions = res.loggedInUserDetails.rbac.rbac_policies;
              if (
                this.userDetails.account?.licenses?.license_data &&
                this.userDetails.account.account_type != 'ROOT'
              ) {
                this.activeaccounts =
                  this.userDetails.account.licenses.license_data;
                this.selectedBsp = this.activeaccounts[0];
              }
              if (this.userDetails.account.ocpm_channel === 'WABA') {
                // const indx = this.channels.findIndex(
                //   (t) => t.ch_name === 'WhatsApp Business API'
                // );
                // this.channels[indx].disabled = false;
                // this.selectedChannel = this.channels[indx];
              }
            }
            if (res.clientId) {
              this.clientId = res.clientId;
            }
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions =
              res.loggedInUserDetails.profile.acl.rbac.rbac_policies;
            const abac_policies =
              this.userDetails.profile.acl.abac.abac_policies;
            if (abac_policies.waba_policies.length > 0) {
              this.activeaccounts = [];
              abac_policies.waba_policies.forEach((t: any) => {
                const presentacc =
                  this.userDetails.account.licenses.license_data.find(
                    (m: any) =>
                      m.channel_credentials.waba_number === t.waba_number
                  );
                if (presentacc) {
                  this.activeaccounts.push(presentacc);
                }
              });
              const otherbsps = this.activeaccounts.find(
                (t: any) => t.bsp !== 'RML'
              );
              if (otherbsps) {
                return;
              } else {
                this.activeaccounts.forEach((t: any) => {
                  if (this.bsp.indexOf(t.bsp) === -1) {
                    this.bsp.push(t.bsp);
                  }
                });
                this.selectedBsp = this.bsp[0];
              }
            }
          }
          if (res.sel_ba) {
            this.selectedBsp = res.sel_ba;
          }
        });
    }
    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.selectedBA = value;
        this.account_dtls.account_id = this.selectedBA?.account_id;
        this.account_dtls.channel = this.selectedBA.channel?.toLowerCase();
        this.account_dtls.bsp_id = this.selectedBA.channel_credentials?.bsp_id;
        this.account_dtls.waba_no =
          this.selectedBA.channel_credentials?.waba_number;
        if (this.account_dtls.channel === 'waba') {
          const indx = this.channelsList.findIndex(
            (t) => t.ch_name === 'WhatsApp Business API'
          );
          this.channelsList[indx].disabled = false;
          this.selectedChannel = this.channelsList[indx];
        }
      } else {
        let sel_ba;
        if (this.selectedBA) {
          sel_ba = JSON.parse(JSON.stringify(this.selectedBA));
        }
        environment.debug && console.log('sel_ba', sel_ba);
        if (sel_ba !== undefined) {
          if (sel_ba) {
            sel_ba = JSON.parse(sel_ba);
            this.selectedBA = sel_ba;
          } else {
            this.store.dispatch(
              commonactions.selectedBspDetails({ data: this.activeaccounts[0] })
            );
            this.selectedBA = this.activeaccounts[0];
          }
          environment.debug && console.log('this.selectedBA ', this.selectedBA);
          this.shareservice.selectedBA$.next(this.selectedBA);
          this.account_dtls.account_id = this.selectedBA.account_id;
          this.account_dtls.channel = this.selectedBA.channel.toLowerCase();
          this.account_dtls.bsp_id = this.selectedBA.channel_credentials.bsp_id;
          this.account_dtls.waba_no =
            this.selectedBA.channel_credentials.waba_number;
          if (this.account_dtls.channel === 'waba') {
            const indx = this.channelsList.findIndex(
              (t) => t.ch_name === 'WhatsApp Business API'
            );
            this.channelsList[indx].disabled = false;
            this.selectedChannel = this.channelsList[indx];
          }
        } else if (this.activeaccounts[0]) {
          this.selectedBA = this.activeaccounts[0];
          if (!environment.sso) {
            this.store.dispatch(
              commonactions.selectedBspDetails({ data: this.activeaccounts[0] })
            );
          }
          this.shareservice.selectedBA$.next(this.selectedBA);
          this.account_dtls.account_id = this.selectedBA.account_id;
          this.account_dtls.channel = this.selectedBA.channel.toLowerCase();
          this.account_dtls.bsp_id = this.selectedBA.channel_credentials.bsp_id;
          this.account_dtls.waba_no =
            this.selectedBA.channel_credentials.waba_number;
          if (this.account_dtls.channel === 'waba') {
            const indx = this.channelsList.findIndex(
              (t) => t.ch_name === 'WhatsApp Business API'
            );
            this.channelsList[indx].disabled = false;
            this.selectedChannel = this.channelsList[indx];
          }
        }
      }
    });

    //sso superadmin waba number selection
    if (this.sso) {
      this.store
        .select(selectChannelCredentials)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          const indx = this.channelsList.findIndex(
            (t) => t.ch_name === 'WhatsApp Business API'
          );
          this.channelsList[indx].disabled = false;
          this.selectedChannel = this.channelsList[indx];
          if (res.selectedAccountDetails) {
            this.activeaccounts =
              //this.userDetails.account.licenses.license_data;
              [{ channel_credentials: res.selectedAccountDetails }];
            this.selectedBA = this.activeaccounts[0];
            this.shareservice.selectedBA$.next(this.selectedBA);
          }
        });
    }
  }

  compareFn(item: any, selected: any) {
    return item.ch_name === selected.ch_name;
  }

  onSelectBA(event: any) {
    const isInactive = (
      this.userDetails as AuthUserData
    ).account.licenses.license_data.find(
      (m: any) =>
        m.channel_credentials.waba_number ===
        event.channel_credentials.waba_number
    );

    if (isInactive.is_active) {
      this.store.dispatch(commonactions.selectedBspDetails({ data: event }));
      // localStorage.setItem('sel_ba', JSON.stringify(event));
      const data = JSON.parse(JSON.stringify(event));
      this.shareservice.selectedBA$.next(data);
    } else {
      this.alertMsg.alertWarning(
        'Warning',
        'Number is inactive please contact admin'
      );
    }
  }

  signout() {
    this.modalService.dismissAll();
    if (environment.sso) {
      if (this.clientId) {
        this.store.dispatch(
          commonactions.SsoActions.logoutUser({
            data: { clientId: this.clientId },
          })
        );
      }
    } else {
      this.store.dispatch(authactions.logoutUser());
    }
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true });
  }

  getRoute() {
    return this.shareservice.getRoutingUrl();
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.store.dispatch(dashboardactions.resetChannelCredentials());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
