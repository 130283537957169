export const WhitelabelingData: any = {
  logo: 'assets/img/main_logo.png',
  favicon: 'assets/img/whatsapp.svg',
  loginbackgroundimg: 'assets/img/login_left_bg.svg',
  primary_color: '#782891',
  secondary_color: '#07123f',
  buttons_type: 'rounded',
  formcontrol_type: 'rounded',
  product_name: null,
  company_name: null,
  website: null,
  terms_url: null,
  poweredbyimg: 'assets/img/main_logo.png',
};
