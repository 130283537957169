import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {

  @Input() content: string;
  @Input() limit: number;
  @Input() completeWords: boolean;

  showToggleBtn: boolean;
  isContentToggled: boolean;
  nonFormattedContent: string;
  formattedContent: string;

  ngOnInit() {
    this.nonFormattedContent = this.content;
    this.formattedContent = this.formatContent(this.content);

    if(this.content.length < 300)
    {
      this.showToggleBtn = false;
    }
    else {
      this.content = this.formattedContent;
      this.showToggleBtn = true;
    }
  }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.content = this.isContentToggled ? this.nonFormattedContent : this.formattedContent;
  }

  formatContent(content: string) {
    if (this.completeWords) {
      this.limit = content.substr(0, this.limit).lastIndexOf(' ');
    }
    return `${content.substr(0, this.limit)}...`;
  }

}
