import { Action, createReducer, on } from '@ngrx/store';
import * as authActions from 'src/app/login/store/login.actions';
import { AnalyticsData } from '../appdata/analytics.model';
import { AuthUserData, Ssoprofile, SsoUserData } from '../appdata/auth.model';
import { MediaServerList } from '../appdata/fileserver.model';
import { UploadFileResponse } from '../appdata/fileupload.model';
import * as commonActions from './common.actions';

export interface AuthUserState {
  loggedInUserDetails: AuthUserData;
  token: string;
  sel_ba: any;
  error: any;
}

export const initialAuthUserState: AuthUserState = {
  loggedInUserDetails: null,
  //JSON.parse(localStorage.getItem('wabaauthdata')),
  token: null,
  //localStorage.getItem('waba_token'),
  sel_ba: null,
  error: null,
};

export interface SsoAuthUserState {
  loggedInUserDetails: SsoUserData;
  token: string;
  profileData: Ssoprofile;
  clientId: string;
  loader: boolean;
  error: any;
}

export const initialSsoAuthUserState: SsoAuthUserState = {
  loggedInUserDetails: null,
  //JSON.parse(sessionStorage.getItem('ssoauthdata')),
  token: null,
  //sessionStorage.getItem('sso_token'),
  profileData: null,
  //JSON.parse(sessionStorage.getItem('profile')),
  clientId: null,
  loader: false,
  error: null,
};

export interface CommonState {
  uploadRes: UploadFileResponse;
  analyticsdata: AnalyticsData;
  mediaServerFiles: MediaServerList;
  deletemediafiles_res: any;
  defaultSettings: any;
  fileres: any;
  profilelicensedata: any;
  wlbres: any;
  customData: any;
  error: any;
}

export const initialCommonState: CommonState = {
  uploadRes: null,
  analyticsdata: null,
  mediaServerFiles: null,
  defaultSettings: null,
  deletemediafiles_res: null,
  profilelicensedata: null,
  fileres: null,
  wlbres: null,
  //  localStorage.getItem('waba_wbl')
  //   ? JSON.parse(localStorage.getItem('waba_wbl'))
  //   : null,
  customData: { isLoader: false, isAnalysing: false },
  error: null,
};

const userReducer = createReducer(
  initialAuthUserState,
  on(
    commonActions.userdetails,
    (state, { data }): AuthUserState => ({
      ...state,
      loggedInUserDetails: data,
      //token: null,
      // localStorage.getItem('waba_token')
      //   ? localStorage.getItem('waba_token')
      //   : null,
      error: null,
    })
  ),
  on(
    commonActions.tokendetails,
    (state, { data }): AuthUserState => ({
      ...state,
      //loggedInUserDetails: null,
      // localStorage.getItem('wabaauthdata')
      //   ? JSON.parse(localStorage.getItem('wabaauthdata'))
      //   : null,
      token: data,
      error: null,
    })
  ),
  on(
    commonActions.selectedBspDetails,
    (state, { data }): AuthUserState => ({
      ...state,
      sel_ba: data,
      error: null,
    })
  ),
  on(
    authActions.logoutUser,
    (state): AuthUserState => ({
      ...state,
      loggedInUserDetails: null,
      token: null,
      sel_ba: null,
      error: null,
    })
  )
);

const userSsoReducer = createReducer(
  initialSsoAuthUserState,
  on(
    commonActions.SsoActions.loginUser,
    (state, action): SsoAuthUserState => ({
      ...state,
      // loggedInUserDetails: sessionStorage.getItem('ssoauthdata')
      //   ? JSON.parse(sessionStorage.getItem('ssoauthdata'))
      //   : null,
      // token: sessionStorage.getItem('sso_token')
      //   ? sessionStorage.getItem('sso_token')
      //   : null,
      loader: action.loader ? action.loader : false,
      error: null,
    })
  ),
  on(
    commonActions.SsoActions.setSsoToken,
    (state, { data }): SsoAuthUserState => ({
      ...state,
      // loggedInUserDetails: sessionStorage.getItem('ssoauthdata')
      //   ? JSON.parse(sessionStorage.getItem('ssoauthdata'))
      //   : null,
      token: data,
      error: null,
    })
  ),
  on(
    commonActions.SsoActions.setProfileData,
    (state, { data, clientId }): SsoAuthUserState => ({
      ...state,
      // loggedInUserDetails: sessionStorage.getItem('ssoauthdata')
      //   ? JSON.parse(sessionStorage.getItem('ssoauthdata'))
      //   : null,
      // token: sessionStorage.getItem('sso_token')
      //   ? sessionStorage.getItem('sso_token')
      //   : null,
      profileData: data,
      clientId: clientId,
      loader: false,
      error: null,
    })
  ),
  on(
    commonActions.SsoActions.loginUserSuccess,
    (state, { data }): SsoAuthUserState => ({
      ...state,
      loggedInUserDetails: data,
      // token: sessionStorage.getItem('sso_token')
      //   ? sessionStorage.getItem('sso_token')
      //   : null,
      //   clientId: null,
      loader: false,
      error: null,
    })
  ),
  on(
    commonActions.SsoActions.loginUseFailure,
    (state, action): SsoAuthUserState => ({
      ...state,
      loggedInUserDetails: null,
      token: null,
      loader: false,
      clientId: null,
      error: action.error,
    })
  ),
  on(
    commonActions.SsoActions.logoutUserSuccess,
    (state): SsoAuthUserState => ({
      ...state,
      loggedInUserDetails: null,
      token: null,
      loader: false,
      clientId: null,
      error: null,
    })
  )
);
const commonReducer = createReducer(
  initialCommonState,
  on(
    commonActions.clearuploadFile,
    (state): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: false },
      error: null,
    })
  ),
  on(
    commonActions.initiateuploadFile,
    (state): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: true },
      error: null,
    })
  ),
  on(
    commonActions.initiateuploadFileSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: data,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: false },
      error: null,
    })
  ),
  on(
    commonActions.initiateuploadFileFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: false },
      error: error,
    })
  ),
  on(
    commonActions.initiateuploadUrl,
    (state): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: true },
      error: null,
    })
  ),
  on(
    commonActions.initiateuploadUrlSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: data,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: false },
      error: null,
    })
  ),
  on(
    commonActions.initiateuploadUrlFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: false },
      error: error,
    })
  ),
  on(
    commonActions.fetchMediaServerData,
    (state): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: {
        isLoader: true,
        isAnalysing: false,
      },
      error: null,
    })
  ),
  on(
    commonActions.fetchMediaServerDataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: data,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: false },
      error: null,
    })
  ),
  on(
    commonActions.fetchMediaServerDataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      customData: { isLoader: false, isAnalysing: false },
      error: error,
    })
  ),
  on(
    commonActions.fetchanalyticsdataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: data,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: null,
    })
  ),
  on(
    commonActions.fetchanalyticsdataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      deletemediafiles_res: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.uploadFileDataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: data,
      wlbres: null,
      error: null,
    })
  ),
  on(
    commonActions.uploadFileDataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.getWhiteLabelDataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: data,
      error: null,
    })
  ),
  on(
    commonActions.getWhiteLabelDataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.fetchWhiteLabelDataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: data,
      error: null,
    })
  ),
  on(
    commonActions.fetchWhiteLabelDataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.setWhiteLabelData,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: data,
      error: null,
    })
  ),
  on(
    commonActions.deleteMediaServerDataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: data,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: null,
    })
  ),
  on(
    commonActions.deleteMediaServerDataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.createWhiteLabelDataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: data,
      error: null,
    })
  ),
  on(
    commonActions.createWhiteLabelDataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.updateWhiteLabelDataSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: data,
      error: null,
    })
  ),
  on(
    commonActions.updateWhiteLabelDataFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.resetcommondata,
    (state): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      // wlbres: localStorage.getItem('waba_wbl')
      //   ? JSON.parse(localStorage.getItem('waba_wbl'))
      //   : null,
      error: null,
    })
  ),
  on(
    commonActions.changeDefaultSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: data,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: null,
    })
  ),
  on(
    commonActions.changeDefaultFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.getLicenseDetailsSuccess,
    (state, { data }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: data,
      fileres: null,
      wlbres: null,
      error: null,
    })
  ),
  on(
    commonActions.getLicenseDetailsFailure,
    (state, { error }): CommonState => ({
      ...state,
      uploadRes: null,
      analyticsdata: null,
      mediaServerFiles: null,
      deletemediafiles_res: null,
      defaultSettings: null,
      profilelicensedata: null,
      fileres: null,
      wlbres: null,
      error: error,
    })
  ),
  on(
    commonActions.clearError,
    (state): CommonState => ({
      ...state,
      error: null,
    })
  )
);

export function commonreducer(
  state: CommonState | undefined,
  action: Action
): any {
  return commonReducer(state, action);
}

export function userreducer(
  state: AuthUserState | undefined,
  action: Action
): any {
  return userReducer(state, action);
}

export function userssoreducer(
  state: SsoAuthUserState | undefined,
  action: Action
): any {
  return userSsoReducer(state, action);
}

export const getprofiledata = (state: AuthUserState) => {
  return {
    loggedInUserDetails: state.loggedInUserDetails,
    token: state.token,
    sel_ba: state.sel_ba,
    error: state.error,
  };
};

export const getssoprofiledata = (state: SsoAuthUserState) => {
  return {
    loggedInUserDetails: state.loggedInUserDetails,
    token: state.token,
    profileData: state.profileData,
    clientId: state.clientId,
    error: state.error,
  };
};

export const getcommondata = (state: CommonState) => {
  return {
    uploadRes: state.uploadRes,
    analyticsdata: state.analyticsdata,
    mediaServerFiles: state.mediaServerFiles,
    deletemediafiles_res: state.deletemediafiles_res,
    defaultSettings: state.defaultSettings,
    profilelicensedata: state.profilelicensedata,
    fileres: state.fileres,
    wlbres: state.wlbres,
    customData: state.customData,
    error: state.error,
  };
};

export const getssoauthloader = (state: SsoAuthUserState) => {
  return {
    loader: state.loader,
  };
};
